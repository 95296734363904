//================================================
// アニメーション
//================================================
.js-moveTrigger {
	@if $type == ""{
		opacity: 0;
	}
}

.fadeInUp {
	@if $type == ""{
		opacity: 1;
		animation: fadeInUp 3s forwards;
		animation-timing-function: cubic-bezier(0.19, 1, 0.02, 1);
		animation-play-state: running;
	}
}

@if $type == ""{
@keyframes fadeInUp{
  0% {
    opacity: 0;
    transform: translateY(40px);
  }
	50% {
    opacity: 1;
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

[data-transition-delay="0.1s"]{
	transition-delay: .1s!important;
}
[data-transition-delay="0.2s"]{
	transition-delay: .2s!important;
}
[data-transition-delay="0.3s"]{
	transition-delay: .3s!important;
}
[data-transition-delay="0.4s"]{
	transition-delay: .4s!important;
}
[data-transition-delay="0.5s"]{
	transition-delay: .5s!important;
}
[data-transition-delay="0.6s"]{
	transition-delay: .6s!important;
}
[data-transition-delay="0.7s"]{
	transition-delay: .7s!important;
}
[data-transition-delay="0.8s"]{
	transition-delay: .8s!important;
}
[data-transition-delay="0.9s"]{
	transition-delay: .9s!important;
}
[data-transition-delay="1.0s"]{
	transition-delay: 1s!important;
}
[data-transition-delay="1.1s"]{
	transition-delay: 1.1s!important;
}
[data-transition-delay="1.2s"]{
	transition-delay: 1.2s!important;
}
[data-transition-delay="1.3s"]{
	transition-delay: 1.3s!important;
}
[data-transition-delay="1.4s"]{
	transition-delay: 1.4s!important;
}
[data-transition-delay="1.5s"]{
	transition-delay: 1.5s!important;
}
[data-transition-delay="1.6s"]{
	transition-delay: 1.6s!important;
}
[data-transition-delay="1.7s"]{
	transition-delay: 1.7s!important;
}
[data-transition-delay="1.8s"]{
	transition-delay: 1.8s!important;
}
[data-transition-delay="1.9s"]{
	transition-delay: 1.9s!important;
}
[data-transition-delay="2.0s"]{
	transition-delay: 2s!important;
}
[data-anime-delay="0.1s"]{
	animation-delay: .1s!important;
}
[data-anime-delay="0.2s"]{
	animation-delay: .2s!important;
}
[data-anime-delay="0.3s"]{
	animation-delay: .3s!important;
}
[data-anime-delay="0.4s"]{
	animation-delay: .4s!important;
}
[data-anime-delay="0.5s"]{
	animation-delay: .5s!important;
}
[data-anime-delay="0.6s"]{
	animation-delay: .6s!important;
}
[data-anime-delay="0.7s"]{
	animation-delay: .7s!important;
}
[data-anime-delay="0.8s"]{
	animation-delay: .8s!important;
}
[data-anime-delay="0.9s"]{
	animation-delay: .9s!important;
}
[data-anime-delay="1.0s"]{
	animation-delay: 1s!important;
}
[data-anime-delay="1.1s"]{
	animation-delay: 1.1s!important;
}
[data-anime-delay="1.2s"]{
	animation-delay: 1.2s!important;
}
[data-anime-delay="1.3s"]{
	animation-delay: 1.3s!important;
}
[data-anime-delay="1.4s"]{
	animation-delay: 1.4s!important;
}
[data-anime-delay="1.5s"]{
	animation-delay: 1.5s!important;
}
[data-anime-delay="1.6s"]{
	animation-delay: 1.6s!important;
}
[data-anime-delay="1.7s"]{
	animation-delay: 1.7s!important;
}
[data-anime-delay="1.8s"]{
	animation-delay: 1.8s!important;
}
[data-anime-delay="1.9s"]{
	animation-delay: 1.9s!important;
}
[data-anime-delay="2.0s"]{
	animation-delay: 2s!important;
}
}

@if $type == sp{
	.sp_delay_none{
		animation-delay: unset!important;
		transition-delay: unset!important;
	}
}
@if $type == pc{
	.pc_delay_none{
		animation-delay: unset!important;
		transition-delay: unset!important;
	}
}

@charset "UTF-8";
/* --------------- variables --------------------- */
/* Break Point */
/* Color */
/*サイトカラー*/
/*パープル*/
/*オレンジ*/
/*オレンジ*/
/* other */
/*transition-timing-function*/
main {
  overflow-x: hidden;
}

h2 {
  text-align: center;
}

.read {
  line-height: 2;
}

#firstview {
  position: relative;
}
#firstview .fvOverlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: calc(100% - 5px);
  background: rgba(0, 0, 0, 0.5);
  z-index: 1;
}
#firstview .fvImgArea img {
  width: 100%;
  object-fit: cover;
}
#firstview .fvTextArea {
  color: #fff;
  position: absolute;
  z-index: 2;
}
#firstview .fvTextArea .subCopy,
#firstview .fvTextArea .mainCopy,
#firstview .fvTextArea .desc {
  text-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
}
#firstview .fvTextArea .subCopy {
  font-weight: bold;
}
#firstview .fvTextArea .mainCopy {
  font-weight: 900;
  line-height: 1.42;
}
#firstview .fvTextArea .desc {
  font-weight: bold;
}
#firstview .fvBtnList li {
  position: relative;
}
#firstview .fvBtnList li a {
  color: #fff;
  padding: 0;
  display: block;
  font-weight: bold;
  text-align: center;
  border-radius: 3px;
}
#firstview .fvBtnList li a span {
  position: relative;
}
#firstview .fvBtnList li a .icon {
  margin-right: 25px;
  display: inline-block;
}
#firstview .fvBtnList li a .icon::before {
  content: "";
  background-repeat: no-repeat;
  background-size: contain;
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}
#firstview .fvBtnList li a .icon.iconMaterial::before {
  background-image: url(../images/common/icon_document_white.svg);
}
#firstview .fvBtnList li a .icon.iconMail::before {
  background-image: url(../images/common/icon_mail_white.svg);
}
#firstview .fvBtnList li.btnMaterial a {
  background-color: #f07800;
  border: 1px solid #fff;
  transition: 0.3s background, 0.3s color cubic-bezier(0.25, 0.1, 0, 0.72);
}
#firstview .fvBtnList li.btnContact a {
  background-color: #17184b;
  border: 1px solid #fff;
  color: #fff;
  transition: 0.3s background, 0.3s color cubic-bezier(0.25, 0.1, 0, 0.72);
}

#zikashouhi {
  background-color: #fff;
  position: relative;
  z-index: 1;
}
#zikashouhi::before, #zikashouhi::after {
  content: "";
  background-color: #f9f9f9;
  display: block;
  position: absolute;
  z-index: -1;
}
#zikashouhi::before {
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}
#zikashouhi::after {
  right: 0;
}
#zikashouhi h2 {
  color: #17184b;
  font-weight: bold;
  line-height: 1.76;
  text-align: center;
}
#feature {
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
}
#feature h2 {
  color: #fff;
  text-shadow: 0px 0px 10px #272751;
}
#feature .desc {
  color: #fff;
  text-shadow: 0px 0px 10px #272751;
}
#caseStudy {
  background-color: #f8f8f8;
}
#caseStudy .inner {
  padding: 0;
}
#whatIsSolar {
  background-color: #17184b;
}
#whatIsSolar .fixedArea {
  position: relative;
}
#whatIsSolar .fixedArea .fixedAreaIn {
  width: 100%;
}
#whatIsSolar .fixedArea h2 {
  color: #fff;
}
#whatIsSolar .textArea {
  background-color: #fff;
  color: #17184b;
}
#whatIsSolar .titleBlock .case {
  font-family: "Jost", sans-serif;
  font-weight: bold;
}
#serviceFlow {
  background: url(../images/home/flow_bg.png) no-repeat center 0;
  background-size: cover;
}
#serviceFlow .titleBlock {
  background-color: #17184b;
  text-align: center;
}
#serviceFlow .titleBlock .en,
#serviceFlow .titleBlock .ja {
  color: #fff;
  display: block;
}
#serviceFlow .titleBlock .en {
  font-family: "Jost", sans-serif;
}
#serviceFlow .titleBlock .ja {
  font-weight: bold;
}
#serviceFlow .flowList {
  background-color: #f8f8f8;
  display: flex;
  flex-flow: wrap;
  position: relative;
}
#serviceFlow .flowList::before {
  content: "";
  background-color: #e7e7e7;
  display: block;
  position: absolute;
  left: 50%;
  transition: 1.2s all cubic-bezier(0.25, 0.1, 0, 0.72);
}
#serviceFlow .flowList li {
  background: #f8f8f8;
  position: relative;
  transition: 0.5s box-shadow cubic-bezier(0.25, 0.1, 0, 0.72);
  transition-delay: 0.5s;
  will-change: transform, opacity;
  opacity: 0;
}
#serviceFlow .flowList li:last-child::after {
  content: none;
}
#serviceFlow .flowList li::after {
  content: "";
  background: url(../images/common/neumo_parts.png) no-repeat;
  background-size: contain;
  display: block;
  position: absolute;
  transform: translateY(-50%);
}
#serviceFlow .flowList li .icon {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.5s all cubic-bezier(0.25, 0.1, 0, 0.72);
  transition-delay: 0.5s;
}
#serviceFlow .flowList li .step {
  display: block;
  font-family: "Jost", sans-serif;
  font-weight: bold;
}
#serviceFlow .flowList li .flowTitle {
  font-weight: bold;
}
#serviceFlow .flowList li .desc {
  position: relative;
}
#serviceFlow .flowList li .desc::before {
  content: "";
  background-color: #17184b;
  display: block;
  position: absolute;
}

#materials {
  background-color: #f9f9f9;
}
#materials .col2Block .imgArea .floatImg:nth-child(even) {
  margin-left: auto;
}
#materials .col2Block .imgArea .floatImg + .floatImg {
  margin-top: 10px;
}
#materials .col2Block h2,
#materials .col2Block .read {
  text-align: left;
}
.slideList .slideListItem .img {
  overflow: hidden;
}
.slideList .slideListItem .img img {
  width: 100%;
  transition: 0.3s transform cubic-bezier(0.25, 0.1, 0, 0.72);
}
.slideList .slideListItem > a {
  display: block;
  transition: 0.3s transform cubic-bezier(0.25, 0.1, 0, 0.72);
}
.slideList .slideListItem .slideListTitle {
  color: #17184b;
  font-weight: bold;
  margin-top: 16px;
}

.slick-list .img {
  overflow: hidden;
}
.slick-list .img img {
  width: 100%;
  transition: 0.3s transform cubic-bezier(0.25, 0.1, 0, 0.72);
}
.slick-dots {
  display: block;
  text-align: center;
  padding: 0;
  margin: -2px 0 0;
  width: 100%;
}
.slick-dots li {
  position: relative;
  display: inline-block;
  margin: 0 8px;
  cursor: pointer;
}
.slick-dots li button {
  background: transparent;
  border-radius: 50%;
  border: 1px solid #17184b;
  display: block;
  outline: none;
  line-height: 0px;
  font-size: 0px;
  padding: 4px;
  cursor: pointer;
}
.slick-dots li button:hover, .slick-dots li button:focus {
  outline: none;
}
.slick-dots li button:before {
  position: absolute;
  top: 0;
  left: 0;
  line-height: 20px;
  text-align: center;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button {
  background: #17184b;
}

.slick-arrow {
  display: block;
  background-color: #fff;
  border: none;
  font-size: 0;
  border: 1px solid #17184b;
  z-index: 3;
  cursor: pointer;
  transition: 0.3s all cubic-bezier(0.25, 0.1, 0, 0.72);
}
.slick-arrow::before {
  transition: 0.3s border cubic-bezier(0.25, 0.1, 0, 0.72);
}
.slick-arrow.prev {
  border-right: none;
  border-radius: 3px 0 0 3px;
  cursor: pointer;
  position: absolute;
  bottom: -10px;
  right: 60px;
}
.slick-arrow.prev::before {
  content: "";
  display: block;
  border-right: 1px solid #17184b;
  border-bottom: 1px solid #17184b;
  position: absolute;
  transform: translate(-50%, -50%) rotate(-225deg);
}
.slick-arrow.next {
  border-radius: 0 3px 3px 0;
  cursor: pointer;
  position: absolute;
  bottom: -10px;
  right: 20px;
}
.slick-arrow.next::before {
  content: "";
  display: block;
  border-right: 1px solid #17184b;
  border-bottom: 1px solid #17184b;
  position: absolute;
  transform: translate(-50%, -50%) rotate(-45deg);
}

#logoBnr .logoList {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
#logoBnr .logoList > li {
  overflow: hidden;
  border: 1px solid #ececec;
}

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}
[dir=rtl] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

.js-moveTrigger {
  opacity: 0;
}

.fadeInUp {
  opacity: 1;
  animation: fadeInUp 3s forwards;
  animation-timing-function: cubic-bezier(0.19, 1, 0.02, 1);
  animation-play-state: running;
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(40px);
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
[data-transition-delay="0.1s"] {
  transition-delay: 0.1s !important;
}

[data-transition-delay="0.2s"] {
  transition-delay: 0.2s !important;
}

[data-transition-delay="0.3s"] {
  transition-delay: 0.3s !important;
}

[data-transition-delay="0.4s"] {
  transition-delay: 0.4s !important;
}

[data-transition-delay="0.5s"] {
  transition-delay: 0.5s !important;
}

[data-transition-delay="0.6s"] {
  transition-delay: 0.6s !important;
}

[data-transition-delay="0.7s"] {
  transition-delay: 0.7s !important;
}

[data-transition-delay="0.8s"] {
  transition-delay: 0.8s !important;
}

[data-transition-delay="0.9s"] {
  transition-delay: 0.9s !important;
}

[data-transition-delay="1.0s"] {
  transition-delay: 1s !important;
}

[data-transition-delay="1.1s"] {
  transition-delay: 1.1s !important;
}

[data-transition-delay="1.2s"] {
  transition-delay: 1.2s !important;
}

[data-transition-delay="1.3s"] {
  transition-delay: 1.3s !important;
}

[data-transition-delay="1.4s"] {
  transition-delay: 1.4s !important;
}

[data-transition-delay="1.5s"] {
  transition-delay: 1.5s !important;
}

[data-transition-delay="1.6s"] {
  transition-delay: 1.6s !important;
}

[data-transition-delay="1.7s"] {
  transition-delay: 1.7s !important;
}

[data-transition-delay="1.8s"] {
  transition-delay: 1.8s !important;
}

[data-transition-delay="1.9s"] {
  transition-delay: 1.9s !important;
}

[data-transition-delay="2.0s"] {
  transition-delay: 2s !important;
}

[data-anime-delay="0.1s"] {
  animation-delay: 0.1s !important;
}

[data-anime-delay="0.2s"] {
  animation-delay: 0.2s !important;
}

[data-anime-delay="0.3s"] {
  animation-delay: 0.3s !important;
}

[data-anime-delay="0.4s"] {
  animation-delay: 0.4s !important;
}

[data-anime-delay="0.5s"] {
  animation-delay: 0.5s !important;
}

[data-anime-delay="0.6s"] {
  animation-delay: 0.6s !important;
}

[data-anime-delay="0.7s"] {
  animation-delay: 0.7s !important;
}

[data-anime-delay="0.8s"] {
  animation-delay: 0.8s !important;
}

[data-anime-delay="0.9s"] {
  animation-delay: 0.9s !important;
}

[data-anime-delay="1.0s"] {
  animation-delay: 1s !important;
}

[data-anime-delay="1.1s"] {
  animation-delay: 1.1s !important;
}

[data-anime-delay="1.2s"] {
  animation-delay: 1.2s !important;
}

[data-anime-delay="1.3s"] {
  animation-delay: 1.3s !important;
}

[data-anime-delay="1.4s"] {
  animation-delay: 1.4s !important;
}

[data-anime-delay="1.5s"] {
  animation-delay: 1.5s !important;
}

[data-anime-delay="1.6s"] {
  animation-delay: 1.6s !important;
}

[data-anime-delay="1.7s"] {
  animation-delay: 1.7s !important;
}

[data-anime-delay="1.8s"] {
  animation-delay: 1.8s !important;
}

[data-anime-delay="1.9s"] {
  animation-delay: 1.9s !important;
}

[data-anime-delay="2.0s"] {
  animation-delay: 2s !important;
}

@media screen and (max-width: 768px) {
  .inner {
    padding: 0 20px;
  }
  .secHome {
    padding: 55px 0;
  }
  h2 {
    font-size: 23px;
  }
  .read {
    margin-top: 20px;
  }
  .btnWrap {
    margin-top: 35px;
  }
  #firstview .fvImgArea img {
    height: 500px;
  }
  #firstview .fvImgArea video {
    width: 100%;
    height: 550px;
    object-fit: cover;
  }
  #firstview .fvTextArea {
    width: 100%;
    padding: 0 10px;
    top: 70px;
  }
  #firstview .fvTextArea .subCopy {
    font-size: 16px;
    text-align: center;
  }
  #firstview .fvTextArea .mainCopy {
    font-size: 29px;
    margin-top: 10px;
    text-align: center;
  }
  #firstview .fvTextArea .desc {
    font-size: 13px;
    line-height: 1.92;
    margin-top: 30px;
    text-align: center;
  }
  #firstview .fvBtnList {
    margin-top: 30px;
  }
  #firstview .fvBtnList li {
    margin: auto;
    width: 270px;
  }
  #firstview .fvBtnList li + li {
    margin-top: 20px;
  }
  #firstview .fvBtnList li a {
    font-size: 14px;
    padding: 13px 10px;
  }
  #firstview .fvBtnList li a .icon.iconMaterial::before {
    width: 14px;
    height: 18px;
  }
  #firstview .fvBtnList li a .icon.iconMail::before {
    width: 18px;
    height: 12px;
  }
  #zikashouhi::before {
    width: 18.5vw;
    height: calc(100% - 170px);
  }
  #zikashouhi::after {
    width: 18.3vw;
    height: calc(100% - 293px);
    top: 16px;
  }
  #zikashouhi .desc {
    margin-top: 20px;
    line-height: 1.86;
  }
  #feature {
    background-image: url(../images/home/sp/feature_img01.jpg);
    height: 443px;
    padding: 55px 0 60px;
  }
  #feature h2 {
    font-size: 23px;
  }
  #feature .desc {
    line-height: 1.86;
    margin-top: 20px;
  }
  #feature .btnWrap {
    margin-top: 36px;
  }
  #caseStudy h2,
  #caseStudy .read,
  #caseStudy .desc,
  #caseStudy .btnWrap {
    padding-right: 20px;
    padding-left: 20px;
  }
  #caseStudy .btnWrap {
    margin-top: 40px;
  }
  #recommend .inner {
    padding: 0;
  }
  #recommend h2,
  #recommend .read,
  #recommend .btnWrap {
    padding-right: 20px;
    padding-left: 20px;
  }
  #whatIsSolar .fixedArea h2 {
    font-size: 23px;
    line-height: 1.65;
  }
  #whatIsSolar .textArea {
    margin-top: 40px;
    padding: 25px 20px;
  }
  #whatIsSolar .textElm + .textElm {
    margin-top: 35px;
  }
  #whatIsSolar .titleBlock .case {
    font-size: 12px;
  }
  #whatIsSolar .titleBlock .case .num {
    font-size: 12px;
  }
  #whatIsSolar .titleBlock h3 {
    font-size: 19px;
  }
  #whatIsSolar p {
    line-height: 1.8;
    margin-top: 14px;
  }
  #serviceFlow {
    background-position: left;
  }
  #serviceFlow .titleBlock {
    padding: 15px;
  }
  #serviceFlow .titleBlock .en {
    font-size: 11px;
  }
  #serviceFlow .titleBlock .ja {
    font-size: 19px;
  }
  #serviceFlow .flowList {
    padding: 30px 30px;
  }
  #serviceFlow .flowList.flowListPath::before {
    transform: translateX(-50%) scale(1, 1);
  }
  #serviceFlow .flowList::before {
    width: 50px;
    height: calc(100% - 65px);
    transform-origin: top;
    transform: translateX(-50%) scale(1, 0);
  }
  #serviceFlow .flowList li {
    border-radius: 10px;
    display: flex;
    align-items: center;
    padding: 18px;
    width: 100%;
  }
  #serviceFlow .flowList li + li {
    margin-top: 30px;
  }
  #serviceFlow .flowList li.fuwa, #serviceFlow .flowList li.fuwa .icon {
    box-shadow: 6px 5px 12px 2px #cccccc, -8px -9px 11px 5px #ffffff;
  }
  #serviceFlow .flowList li::after {
    width: 15px;
    height: 30px;
    bottom: -22px;
    left: 50%;
    transform: translateX(-50%) rotate(90deg);
  }
  #serviceFlow .flowList li .iconArea {
    width: 75px;
    margin-right: 20px;
  }
  #serviceFlow .flowList li .textArea {
    width: calc(100% - 75px);
  }
  #serviceFlow .flowList li .icon {
    border-radius: 10px;
    width: 75px;
    height: 75px;
  }
  #serviceFlow .flowList li .icon img {
    width: 65%;
  }
  #serviceFlow .flowList li .step {
    font-size: 12px;
  }
  #serviceFlow .flowList li .step .num {
    font-size: 18px;
  }
  #serviceFlow .flowList li .flowTitle {
    font-size: 16px;
  }
  #serviceFlow .flowList li .desc {
    margin-top: 15px;
    line-height: 1.5;
  }
  #serviceFlow .flowList li .desc::before {
    width: 20px;
    height: 1px;
    top: -8px;
    left: 0;
  }
  #materials .col2Block .imgArea {
    padding: 0 20px;
  }
  #materials .col2Block .imgArea .floatImg {
    width: 52vw;
  }
  #materials .col2Block .textArea {
    margin-top: 20px;
  }
  #materials .col2Block h2 {
    text-align: center;
  }
  .slick-list {
    padding: 30px 8% !important;
  }
  .slick-list .slick-slide {
    margin: 0 10px;
  }
  .slick-dots {
    padding: 0 100px;
  }
  .slick-dots li {
    height: 10px;
    width: 10px;
  }
  .slick-dots li button {
    height: 7px;
    width: 7px;
  }
  .slick-dots li button:before {
    width: 20px;
    height: 20px;
  }
  .slick-arrow {
    box-shadow: 1px 1px 0 0 #17184b;
    width: 40px;
    height: 38px;
  }
  .slick-arrow.prev::before {
    top: calc(50% + 1px);
    left: calc(50% + 2px);
    width: 8px;
    height: 8px;
  }
  .slick-arrow.next::before {
    top: calc(50% + 1px);
    left: calc(50% - 2px);
    width: 8px;
    height: 8px;
  }
  #logoBnr .logoList {
    margin-top: 22px;
    gap: 10px 3.28%;
  }
  #logoBnr .logoList > li {
    width: 31.1333333333%;
  }
  /* Slider */
  .sp_delay_none {
    animation-delay: unset !important;
    transition-delay: unset !important;
  }
}
@media print, screen and (min-width: 769px) {
  .inner {
    width: 1400px;
    max-width: 90%;
    margin: 0 auto;
  }
  .secHome {
    padding: 80px 0;
  }
  h2 {
    font-size: 34px;
  }
  .read {
    margin-top: 40px;
    text-align: center;
  }
  #firstview .fvImgArea img {
    height: 694px;
  }
  #firstview .fvImgArea video {
    width: 100%;
    height: auto;
  }
  #firstview .fvTextArea {
    margin: 0 auto;
    width: 1400px;
    max-width: 90%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  #firstview .fvTextArea .subCopy {
    font-size: 26px;
  }
  #firstview .fvTextArea .mainCopy {
    font-size: 56px;
    margin-top: 10px;
  }
  #firstview .fvTextArea .desc {
    margin-top: 15px;
    line-height: 2.06;
  }
  #firstview .fvBtnList {
    font-size: 0;
    margin-top: 40px;
  }
  #firstview .fvBtnList li {
    display: inline-block;
    width: 250px;
  }
  #firstview .fvBtnList li a {
    font-size: 18px;
    padding: 16px 10px;
  }
  #firstview .fvBtnList li a .icon.iconMaterial::before {
    width: 16px;
    height: 21px;
  }
  #firstview .fvBtnList li a .icon.iconMail {
    margin-right: 35px;
  }
  #firstview .fvBtnList li a .icon.iconMail::before {
    width: 22px;
    height: 17px;
  }
  #firstview .fvBtnList li.btnMaterial {
    margin-right: 20px;
  }
  #firstview .fvBtnList li.btnMaterial a:hover {
    background-color: #fff;
    color: #f07800;
    border-color: #f07800;
  }
  #firstview .fvBtnList li.btnMaterial a:hover .iconMaterial::before {
    background-image: url(../images/common/icon_document_orange.svg);
  }
  #firstview .fvBtnList li.btnContact a:hover {
    background-color: #fff;
    border-color: #17184b;
    color: #17184b;
  }
  #firstview .fvBtnList li.btnContact a:hover .iconMail::before {
    background-image: url(../images/common/icon_mail.svg);
  }
  #zikashouhi {
    padding: 80px 0;
  }
  #zikashouhi::before {
    width: 27.4vw;
    height: calc(100% - 170px);
  }
  #zikashouhi::after {
    width: 27.9vw;
    height: calc(100% - 250px);
    top: 0;
  }
  #zikashouhi .inner {
    width: 52vw;
    min-width: 740px;
  }
  #zikashouhi h2 {
    font-size: 34px;
  }
  #zikashouhi .desc {
    margin-top: 40px;
    line-height: 2;
  }
  #feature {
    background-image: url(../images/home/feature_img01.jpg);
    padding: 60px 0;
    height: 520px;
    margin: 0 auto;
    width: 1400px;
    max-width: 100%;
  }
  #feature .textArea {
    width: 480px;
    margin-left: auto;
  }
  #feature .textArea .commonBtn {
    margin-left: 0;
  }
  #feature h2 {
    font-size: 34px;
    line-height: 1.76;
    text-align: left;
  }
  #feature .desc {
    line-height: 2;
    margin-top: 20px;
  }
  #caseStudy {
    margin-top: 30px;
  }
  #whatIsSolar .col2Block {
    display: flex;
    justify-content: center;
  }
  #whatIsSolar .fixedArea {
    width: 410px;
    margin-top: 170px;
    margin-right: 40px;
  }
  #whatIsSolar .fixedArea h2 {
    line-height: 1.76;
    text-align: left;
    white-space: nowrap;
  }
  #whatIsSolar .fixedArea #js-fixedTitle.fixed {
    position: fixed;
    top: 350px;
  }
  #whatIsSolar .fixedArea #js-fixedTitle.absolute {
    position: absolute;
    bottom: 200px;
    top: auto;
  }
  #whatIsSolar .textArea {
    padding: 50px 80px;
    width: calc(100% - 450px);
    max-width: 732px;
  }
  #whatIsSolar .textElm + .textElm {
    margin-top: 75px;
  }
  #whatIsSolar .titleBlock .case .num {
    font-size: 14px;
  }
  #whatIsSolar .titleBlock h3 {
    font-size: 26px;
  }
  #whatIsSolar p {
    line-height: 2.12;
    margin-top: 20px;
  }
  #serviceFlow .titleBlock {
    padding: 20px;
  }
  #serviceFlow .titleBlock .en {
    font-size: 18px;
  }
  #serviceFlow .titleBlock .ja {
    font-size: 30px;
  }
  #serviceFlow .flowList {
    padding: 50px 60px;
  }
  #serviceFlow .flowList.flowListPath::before {
    transform: translate(-50%, -50%) scale(1, 1);
  }
  #serviceFlow .flowList::before {
    width: calc(100% - 130px);
    height: 70px;
    top: 50%;
    transform-origin: left;
    transform: translate(-50%, -50%) scale(0, 1);
  }
  #serviceFlow .flowList li {
    border-radius: 20px;
    width: calc(25% - 38px);
    padding: 18px;
  }
  #serviceFlow .flowList li:not(:nth-child(4n)) {
    margin-right: 50px;
  }
  #serviceFlow .flowList li:nth-child(n+5) {
    margin-top: 40px;
  }
  #serviceFlow .flowList li.fuwa, #serviceFlow .flowList li.fuwa .icon {
    box-shadow: 6px 5px 12px 2px #cccccc, -8px -9px 11px 5px #ffffff;
  }
  #serviceFlow .flowList li::after {
    width: 18px;
    height: 38px;
    top: 50%;
    right: -18px;
    transform: translateY(-50%);
  }
  #serviceFlow .flowList li .icon,
  #serviceFlow .flowList li .step,
  #serviceFlow .flowList li .flowTitle,
  #serviceFlow .flowList li .desc {
    text-align: center;
  }
  #serviceFlow .flowList li .icon {
    border-radius: 20px;
    width: 120px;
    height: 120px;
    margin: auto;
  }
  #serviceFlow .flowList li .step {
    font-size: 16px;
    margin-top: 20px;
  }
  #serviceFlow .flowList li .step .num {
    font-size: 25px;
  }
  #serviceFlow .flowList li .flowTitle {
    font-size: 20px;
  }
  #serviceFlow .flowList li .desc {
    margin-top: 30px;
    line-height: 1.81;
  }
  #serviceFlow .flowList li .desc::before {
    width: 28px;
    height: 2px;
    top: -15px;
    left: 50%;
    transform: translateX(-50%);
  }
  #materials .col2Block {
    display: flex;
    align-items: center;
  }
  #materials .col2Block .imgArea {
    margin-right: 80px;
    width: 38.1vw;
  }
  #materials .col2Block .imgArea .floatImg {
    width: 24.3vw;
  }
  #materials .col2Block .textArea {
    width: calc(100% - 38.1vw);
    min-width: 507px;
  }
  #materials .col2Block .btnWrap {
    margin-top: 45px;
  }
  #materials .col2Block .commonBtn {
    margin-left: 0;
  }
  .slideList {
    display: flex;
    margin-top: 40px;
  }
  .slideList .slideListItem {
    width: 32.3%;
  }
  .slideList .slideListItem:not(:last-child) {
    margin-right: 1%;
  }
  .slideList .slideListItem > a:hover img {
    transform: scale(1.1);
  }
  .slideList .slideListItem > a:hover .moreBtn > span {
    background-image: url(../images/common/icon_arrow_more_hov.svg);
  }
  .slick-list {
    padding: 30px 0 !important;
  }
  .slick-list .slick-slide {
    transform: scale(0.95);
    transition: 0.3s transform cubic-bezier(0.25, 0.1, 0, 0.72);
    transform-origin: bottom;
  }
  .slick-list .slick-slide:not(:last-child) {
    margin-right: 0;
  }
  .slick-list .slick-slide:not(.slick-center):hover img {
    transform: scale(1.1);
  }
  .slick-dots {
    padding-right: 120px;
    text-align: right;
  }
  .slick-dots li {
    height: 10px;
    width: 10px;
  }
  .slick-dots li button {
    height: 7px;
    width: 7px;
  }
  .slick-dots li button:before {
    width: 20px;
    height: 20px;
  }
  .slick-arrow {
    box-shadow: 2px 2px 0 0 #17184b;
    width: 40px;
    height: 30px;
  }
  .slick-arrow:hover {
    background-color: #17184b;
  }
  .slick-arrow:hover::before {
    border-color: #fff;
  }
  .slick-arrow.prev::before {
    top: calc(50% + 1px);
    left: calc(50% + 2px);
    width: 8px;
    height: 8px;
  }
  .slick-arrow.next::before {
    top: calc(50% + 1px);
    left: calc(50% - 2px);
    width: 8px;
    height: 8px;
  }
  #logoBnr .logoList {
    margin-top: 45px;
    gap: 25px 2.15%;
  }
  #logoBnr .logoList > li {
    width: 14.8666666667%;
  }
  /* Slider */
  .pc_delay_none {
    animation-delay: unset !important;
    transition-delay: unset !important;
  }
}
@media screen and (min-width: 769px) and (max-width: 1200px) {
  #firstview .fvImgArea video {
    height: 400px;
    object-fit: cover;
  }
  #firstview .fvTextArea .subCopy {
    font-size: 20px;
  }
  #firstview .fvTextArea .mainCopy {
    font-size: 40px;
  }
  #firstview .fvTextArea .desc {
    font-size: 14px;
  }
  #firstview .fvBtnList {
    margin-top: 30px;
  }
  #firstview .fvBtnList li a {
    font-size: 14px;
  }
  #feature {
    padding: 60px 0px;
  }
  #whatIsSolar .textArea {
    padding: 50px 40px;
  }
  #serviceFlow .flowList.flowListPath::before {
    transform: translate(-50%, -50%) scale(0);
  }
  #serviceFlow .flowList li {
    width: calc(50% - 38px);
  }
  #serviceFlow .flowList li:not(:nth-child(4n)) {
    margin-right: 0;
  }
  #serviceFlow .flowList li:nth-child(n+5) {
    margin-top: 0;
  }
  #serviceFlow .flowList li:not(:nth-child(2n)) {
    margin-right: 50px;
  }
  #serviceFlow .flowList li:nth-child(n+3) {
    margin-top: 40px;
  }
  #materials .col2Block .textArea {
    min-width: auto;
  }
  #logoBnr .logoList {
    margin-top: 30px;
    gap: 15px 2.15%;
  }
  #logoBnr .logoList > li {
    width: 23.3%;
  }
  /* Slider */
}
@media screen and (min-width: 1700px) {
  #firstview .fvTextArea {
    left: 50%;
  }
  /* Slider */
}
@charset "UTF-8";

// IMPPRT INIT

@import "_setting";
$type: "";
@import "_home";
@import "_keyframes";

// FOR SMARTPHONE DISPLAY SIZE
//-----------------------------------------------------------------
@media screen and (max-width:$breakpoint_sp) {
	$type: sp;
	@import "_home";
	@import "_keyframes";
}


// FOR LARGE DISPLAY SIZE
//-----------------------------------------------------------------
@media print, screen and (min-width:$breakpoint_pc) {
	$type: pc;
	@import "_home";
	@import "_keyframes";
}


// FOR TABLET DISPLAY SIZE
//-----------------------------------------------------------------
@media screen and (min-width:$breakpoint_pc) and (max-width:$breakpoint_tb) {
  $type: tb;
  @import "_home";
	@import "_keyframes";
}

// FOR FREE SIZE
//-----------------------------------------------------------------
@media screen and (min-width:$breakpoint_pc1700) {
  $type: pc1700;
  @import "_home";
}

// STYLE FOR PRINT
//-----------------------------------------------------------------
@media print {
	$type: print;
}

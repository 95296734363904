//================================================
// ホーム共通
//================================================
main {
  @if $type=="" {
    overflow-x: hidden;
  }
}

.inner {
  @if $type==sp {
    padding: 0 20px;
  }

  @if $type==pc {
    width: 1400px;
    max-width: 90%;
    margin: 0 auto;
  }
}

.secHome {
  @if $type==sp {
    padding: 55px 0;
  }

  @if $type==pc {
    padding: 80px 0;
  }
}

h2 {
  @if $type=="" {
    text-align: center;
  }

  @if $type==sp {
    font-size: 23px;
  }

  @if $type==pc {
    font-size: 34px;
  }
}

.read {
  @if $type=="" {
    line-height: 2;
  }

  @if $type==sp {
    margin-top: 20px;
  }

  @if $type==pc {
    margin-top: 40px;
    text-align: center;
  }
}

.btnWrap {
  @if $type==sp {
    margin-top: 35px;
  }
}

//================================================
// ファーストビュー
//================================================
#firstview {
  @if $type=="" {
    position: relative;
  }

  .fvOverlay {
    @if $type=="" {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: calc(100% - 5px);
      background: rgba(0, 0, 0, .5);
      z-index: 1;
    }
  }

  .fvImgArea {
    img {
      @if $type=="" {
        width: 100%;
        object-fit: cover;
      }

      @if $type==sp {
        height: 500px;
      }

      @if $type==pc {
        height: 694px;
      }
    }

    video {
      @if $type==sp {
        width: 100%;
        height: 550px;
        object-fit: cover;
      }

      @if $type==pc {
        width: 100%;
        height: auto;
      }

      @if $type==tb {
        height: 400px;
        object-fit: cover;
      }
    }
  }

  .fvTextArea {
    @if $type=="" {
      color: #fff;
      position: absolute;
      z-index: 2;
    }

    @if $type==sp {
      width: 100%;
      padding: 0 10px;
      top: 70px;
    }

    @if $type==pc {
      margin: 0 auto;
      // width: 1180px;
      width: 1400px;
      max-width: 90%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    @if $type==pc1700 {
      left: 50%;
    }

    .subCopy,
    .mainCopy,
    .desc {
      @if $type=="" {
        text-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
      }
    }

    .subCopy {
      @if $type=="" {
        font-weight: bold;
      }

      @if $type==sp {
        font-size: 16px;
        // font-size: 4.5vw;
        text-align: center;
      }

      @if $type==pc {
        font-size: 26px;
      }

      @if $type==tb {
        font-size: 20px;
      }
    }

    .mainCopy {
      @if $type=="" {
        font-weight: 900;
        line-height: 1.42;
      }

      @if $type==sp {
        font-size: 29px;
        // font-size: 7.8vw;
        margin-top: 10px;
        text-align: center;
      }

      @if $type==pc {
        font-size: 56px;
        margin-top: 10px;
      }

      @if $type==tb {
        font-size: 40px;
      }
    }

    .desc {
      @if $type=="" {
        font-weight: bold;
      }

      @if $type==sp {
        font-size: 13px;
        // font-size: 3.3vw;
        line-height: 1.92;
        margin-top: 30px;
        text-align: center;
      }

      @if $type==pc {
        margin-top: 15px;
        line-height: 2.06;
      }

      @if $type==tb {
        font-size: 14px;
      }
    }
  }

  .fvBtnList {
    @if $type==sp {
      margin-top: 30px;
    }

    @if $type==pc {
      font-size: 0;
      margin-top: 40px;
    }

    @if $type==tb {
      margin-top: 30px;
    }

    li {
      @if $type=="" {
        position: relative;
      }

      @if $type==sp {
        margin: auto;
        width: 270px;
      }

      @if $type==pc {
        display: inline-block;
        width: 250px;
      }

      &+li {
        @if $type==sp {
          margin-top: 20px;
        }
      }

      a {
        @if $type=="" {
          color: #fff;
          padding: 0;
          display: block;
          font-weight: bold;
          text-align: center;
          border-radius: 3px;
        }

        @if $type==sp {
          font-size: 14px;
          padding: 13px 10px;
        }

        @if $type==pc {
          font-size: 18px;
          padding: 16px 10px;
        }

        @if $type==tb {
          font-size: 14px;
        }

        span {
          @if $type=="" {
            position: relative;
          }
        }

        .icon {
          @if $type=="" {
            margin-right: 25px;
            display: inline-block;

            &::before {
              content: "";
              background-repeat: no-repeat;
              background-size: contain;
              display: block;
              position: absolute;
              top: 50%;
              left: 0;
              transform: translateY(-50%);
            }
          }

          &.iconMaterial {
            &::before {
              @if $type=="" {
                background-image: url(../images/common/icon_document_white.svg);
              }

              @if $type==sp {
                width: 14px;
                height: 18px;
              }

              @if $type==pc {
                width: 16px;
                height: 21px;
              }
            }
          }

          &.iconMail {
            @if $type==sp {}

            @if $type==pc {
              margin-right: 35px;
            }

            &::before {
              @if $type=="" {
                background-image: url(../images/common/icon_mail_white.svg);
              }

              @if $type==sp {
                width: 18px;
                height: 12px;
              }

              @if $type==pc {
                width: 22px;
                height: 17px;
              }
            }
          }
        }
      }

      &.btnMaterial {
        @if $type==pc {
          margin-right: 20px;
        }

        a {
          @if $type=="" {
            background-color: $c_orange;
            border: 1px solid #fff;
            transition: .3s background, .3s color $ttf;
          }

          @if $type==pc {
            &:hover {
              background-color: #fff;
              color: $c_orange;
              border-color: $c_orange;

              .iconMaterial::before {
                background-image: url(../images/common/icon_document_orange.svg);
              }
            }
          }
        }
      }

      &.btnContact {
        a {
          @if $type=="" {
            background-color: $c_purple;
            border: 1px solid #fff;
            color: #fff;
            transition: .3s background, .3s color $ttf;
          }

          @if $type==pc {
            &:hover {
              background-color: #fff;
              border-color: $c_purple;
              color: $c_purple;

              .iconMail::before {
                background-image: url(../images/common/icon_mail.svg);
              }
            }
          }
        }
      }
    }
  }
}

//================================================
// 自家消費型太陽光発電
//================================================
#zikashouhi {
  @if $type=="" {
    background-color: #fff;
    position: relative;
    z-index: 1;
  }

  @if $type==pc {
    padding: 80px 0;
  }

  &::before,
  &::after {
    @if $type=="" {
      content: "";
      background-color: #f9f9f9;
      display: block;
      position: absolute;
      z-index: -1;
    }
  }

  &::before {
    @if $type=="" {
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }

    @if $type==sp {
      width: 18.5vw;
      height: calc(100% - 170px);

    }

    @if $type==pc {
      width: 27.4vw;
      height: calc(100% - 170px);
    }
  }

  &::after {
    @if $type=="" {
      right: 0;
    }

    @if $type==sp {
      width: 18.3vw;
      height: calc(100% - 293px);
      top: 16px;
    }

    @if $type==pc {
      // width: 403px;
      width: 27.9vw;
      height: calc(100% - 250px);
      top: 0;
    }
  }

  .inner {
    @if $type==pc {
      width: 52vw;
      min-width: 740px;
    }
  }

  h2 {
    @if $type=="" {
      color: $c_purple;
      font-weight: bold;
      line-height: 1.76;
      text-align: center;
    }

    @if $type==sp {}

    @if $type==pc {
      font-size: 34px;
    }
  }

  .desc {
    @if $type=="" {}

    @if $type==sp {
      margin-top: 20px;
      line-height: 1.86;
    }

    @if $type==pc {
      margin-top: 40px;
      line-height: 2;
    }
  }
}

//================================================
// 恒電社の特徴
//================================================
#feature {
  @if $type=="" {
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
  }

  @if $type==sp {
    background-image: url(../images/home/sp/feature_img01.jpg);
    height: 443px;
    padding: 55px 0 60px;
  }

  @if $type==pc {
    background-image: url(../images/home/feature_img01.jpg);
    padding: 60px 0;
    // height: 612px;
    height: 520px;
    margin: 0 auto;
    width: 1400px;
    max-width: 100%;
  }

  @if $type==tb {
    padding: 60px 0px;
  }


  .textArea {
    @if $type==sp {}

    @if $type==pc {
      width: 480px;
      margin-left: auto;
    }

    .commonBtn {
      @if $type==pc {
        margin-left: 0;
      }
    }
  }

  h2 {
    @if $type=="" {
      color: #fff;
      text-shadow: 0px 0px 10px #272751;
    }

    @if $type==sp {
      font-size: 23px;
    }

    @if $type==pc {
      font-size: 34px;
      line-height: 1.76;
      text-align: left;
    }
  }

  .desc {
    @if $type=="" {
      color: #fff;
      text-shadow: 0px 0px 10px #272751;
    }

    @if $type==sp {
      line-height: 1.86;
      margin-top: 20px;
    }

    @if $type==pc {
      line-height: 2;
      margin-top: 20px;
    }
  }

  .btnWrap {
    @if $type==sp {
      margin-top: 36px;
    }
  }
}

//================================================
// 導入事例
//================================================
#caseStudy {
  @if $type=="" {
    background-color: #f8f8f8;
  }

  @if $type==pc {
    margin-top: 30px;
  }

  .inner {
    @if $type=="" {
      padding: 0;
    }
  }

  h2,
  .read,
  .desc,
  .btnWrap {
    @if $type==sp {
      padding-right: 20px;
      padding-left: 20px;
    }
  }

  .btnWrap {
    @if $type==sp {
      margin-top: 40px;
    }
  }
}

//================================================
// 恒電社の実績
//================================================
#recommend {
  .inner {
    @if $type==sp {
      padding: 0;
    }
  }

  h2,
  .read,
  .btnWrap {
    @if $type==sp {
      padding-right: 20px;
      padding-left: 20px;
    }
  }
}

//================================================
// タイトル固定のやつ
//================================================
#whatIsSolar {
  @if $type=="" {
    background-color: $c_purple;
  }

  .col2Block {
    @if $type==sp {}

    @if $type==pc {
      display: flex;
      justify-content: center;
    }
  }

  .fixedArea {
    @if $type=="" {
      position: relative;
    }

    @if $type==sp {}

    @if $type==pc {
      width: calc(450px - 40px);
      margin-top: 170px;
      margin-right: 40px;
    }

    .fixedAreaIn {
      @if $type=="" {
        width: 100%;
      }
    }

    h2 {
      @if $type=="" {
        color: #fff;
      }

      @if $type==sp {
        font-size: 23px;
        line-height: 1.65;
      }

      @if $type==pc {
        line-height: 1.76;
        text-align: left;
        white-space: nowrap;
      }
    }

    #js-fixedTitle {
      &.fixed {
        @if $type==pc {
          position: fixed;
          // top: 25%;
          top: 350px;
        }
      }

      &.absolute {
        @if $type==pc {
          position: absolute;
          bottom: 200px;
          top: auto;
        }
      }
    }
  }

  .textArea {
    @if $type=="" {
      background-color: #fff;
      color: $c_purple;
    }

    @if $type==sp {
      margin-top: 40px;
      padding: 25px 20px;
    }

    @if $type==pc {
      padding: 50px 80px;
      width: calc(100% - 450px);
      max-width: 732px;
    }

    @if $type==tb {
      padding: 50px 40px;
    }
  }

  .textElm {
    &+.textElm {
      @if $type==sp {
        margin-top: 35px;
      }

      @if $type==pc {
        margin-top: 75px;
      }
    }
  }

  .titleBlock {
    .case {
      @if $type=="" {
        @include fontEn;
        font-weight: bold;
      }

      @if $type==sp {
        font-size: 12px;
      }

      @if $type==pc {}

      .num {
        @if $type==sp {
          font-size: 12px;
        }

        @if $type==pc {
          font-size: 14px;
        }
      }
    }

    h3 {
      @if $type=="" {}

      @if $type==sp {
        font-size: 19px;
      }

      @if $type==pc {
        font-size: 26px;
      }
    }
  }

  p {
    @if $type==sp {
      line-height: 1.8;
      margin-top: 14px;
    }

    @if $type==pc {
      line-height: 2.12;
      margin-top: 20px;
    }
  }
}

//================================================
// サービスフロー
//================================================
#serviceFlow {
  @if $type=="" {
    background: url(../images/home/flow_bg.png) no-repeat center 0;
    background-size: cover;
  }

  @if $type==sp {
    background-position: left;
  }

  .titleBlock {
    @if $type=="" {
      background-color: $c_purple;
      text-align: center;
    }

    @if $type==sp {
      padding: 15px;
    }

    @if $type==pc {
      padding: 20px;
    }

    .en,
    .ja {
      @if $type=="" {
        color: #fff;
        display: block;
      }
    }

    .en {
      @if $type=="" {
        @include fontEn;
      }

      @if $type==sp {
        font-size: 11px;
      }

      @if $type==pc {
        font-size: 18px;
      }
    }

    .ja {
      @if $type=="" {
        font-weight: bold;
      }

      @if $type==sp {
        font-size: 19px;
      }

      @if $type==pc {
        font-size: 30px;
      }
    }
  }

  .flowList {
    @if $type=="" {
      background-color: #f8f8f8;
      display: flex;
      flex-flow: wrap;
      position: relative;
    }

    @if $type==sp {
      padding: 30px 30px;
    }

    @if $type==pc {
      padding: 50px 60px;
    }

    &.flowListPath {
      &::before {
        @if $type=="" {}

        @if $type==sp {
          transform: translateX(-50%) scale(1, 1);
        }

        @if $type==pc {
          transform: translate(-50%, -50%) scale(1, 1);
        }

        @if $type==tb {
          //非表示
          transform: translate(-50%, -50%) scale(0);
        }
      }
    }

    &::before {
      @if $type=="" {
        content: "";
        background-color: #e7e7e7;
        display: block;
        position: absolute;
        left: 50%;
        transition: 1.2s all $ttf;
      }

      @if $type==sp {
        width: 50px;
        height: calc(100% - 65px);
        transform-origin: top;
        transform: translateX(-50%) scale(1, 0);
      }

      @if $type==pc {
        width: calc(100% - 130px);
        height: 70px;
        top: 50%;
        transform-origin: left;
        transform: translate(-50%, -50%) scale(0, 1);
      }
    }

    li {
      @if $type=="" {
        background: #f8f8f8;
        position: relative;
        transition: .5s box-shadow $ttf;
        transition-delay: .5s;
        will-change: transform, opacity;
        opacity: 0;

        &:last-child {
          &::after {
            content: none;
          }
        }
      }

      @if $type==sp {
        border-radius: 10px;
        display: flex;
        align-items: center;
        padding: 18px;
        width: 100%;

        &+li {
          margin-top: 30px;
        }
      }

      @if $type==pc {
        border-radius: 20px;
        width: calc(25% - 38px);
        padding: 18px;

        &:not(:nth-child(4n)) {
          margin-right: 50px;
        }

        &:nth-child(n + 5) {
          margin-top: 40px;
        }
      }

      @if $type==tb {
        width: calc(50% - 38px);

        &:not(:nth-child(4n)) {
          margin-right: 0;
        }

        &:nth-child(n + 5) {
          margin-top: 0;
        }

        &:not(:nth-child(2n)) {
          margin-right: 50px;
        }

        &:nth-child(n + 3) {
          margin-top: 40px;
        }
      }

      &.fuwa,
      &.fuwa .icon {
        @if $type==sp {
          box-shadow: 6px 5px 12px 2px #cccccc, -8px -9px 11px 5px #ffffff;
        }

        @if $type==pc {
          box-shadow: 6px 5px 12px 2px #cccccc, -8px -9px 11px 5px #ffffff;
        }
      }

      &::after {
        @if $type=="" {
          content: "";
          background: url(../images/common/neumo_parts.png) no-repeat;
          background-size: contain;
          display: block;
          position: absolute;
          transform: translateY(-50%);
        }

        @if $type==sp {
          width: 15px;
          height: 30px;
          bottom: -22px;
          left: 50%;
          transform: translateX(-50%)rotate(90deg);
        }

        @if $type==pc {
          width: 18px;
          height: 38px;
          top: 50%;
          right: -18px;
          transform: translateY(-50%);
        }
      }

      .iconArea {
        @if $type==sp {
          width: 75px;
          margin-right: 20px;
        }
      }

      .textArea {
        @if $type==sp {
          width: calc(100% - 75px);
        }
      }

      .icon,
      .step,
      .flowTitle,
      .desc {
        @if $type==pc {
          text-align: center;
        }
      }

      .icon {
        @if $type=="" {
          display: flex;
          align-items: center;
          justify-content: center;
          transition: .5s all $ttf;
          transition-delay: .5s;
        }

        @if $type==sp {
          border-radius: 10px;
          width: 75px;
          height: 75px;

          img {
            width: 65%;
          }
        }

        @if $type==pc {
          border-radius: 20px;
          width: 120px;
          height: 120px;
          margin: auto;
        }
      }

      .step {
        @if $type=="" {
          display: block;
          @include fontEn;
          font-weight: bold;
        }

        @if $type==sp {
          font-size: 12px;
        }

        @if $type==pc {
          font-size: 16px;
          margin-top: 20px;
        }

        .num {
          @if $type==sp {
            font-size: 18px;
          }

          @if $type==pc {
            font-size: 25px;
          }
        }
      }

      .flowTitle {
        @if $type=="" {
          font-weight: bold;
        }

        @if $type==sp {
          font-size: 16px;
        }

        @if $type==pc {
          font-size: 20px;
        }
      }

      .desc {
        @if $type=="" {
          position: relative;
        }

        @if $type==sp {
          margin-top: 15px;
          line-height: 1.5;
        }

        @if $type==pc {
          margin-top: 30px;
          line-height: 1.81;
        }

        &::before {
          @if $type=="" {
            content: "";
            background-color: $c_purple;
            display: block;
            position: absolute;

          }

          @if $type==sp {
            width: 20px;
            height: 1px;
            top: -8px;
            left: 0;
          }

          @if $type==pc {
            width: 28px;
            height: 2px;
            top: -15px;
            left: 50%;
            transform: translateX(-50%);
          }
        }
      }
    }
  }
}

//================================================
// 資料請求
//================================================
#materials {
  @if $type=="" {
    background-color: #f9f9f9;
  }

  .col2Block {
    @if $type=="" {}

    @if $type==sp {}

    @if $type==pc {
      display: flex;
      align-items: center;
    }

    .imgArea {
      @if $type==sp {
        padding: 0 20px;
      }

      @if $type==pc {
        margin-right: 80px;
        width: 38.1vw;
      }

      .floatImg {
        @if $type=="" {}

        @if $type==sp {
          width: 52vw;
        }

        @if $type==pc {
          width: 24.3vw;
        }

        &:nth-child(even) {
          @if $type=="" {
            margin-left: auto;
          }
        }

        &+.floatImg {
          @if $type=="" {
            margin-top: 10px;
          }
        }
      }
    }

    .textArea {
      @if $type==sp {
        margin-top: 20px;
      }

      @if $type==pc {
        width: calc(100% - 38.1vw);
        min-width: 507px;
      }

      @if $type==tb {
        min-width: auto;
      }
    }

    h2,
    .read {
      @if $type=="" {
        text-align: left;
      }
    }

    h2 {
      @if $type==sp {
        text-align: center;
      }
    }

    .btnWrap {
      @if $type==pc {
        margin-top: 45px;
      }
    }

    .commonBtn {
      @if $type==pc {
        margin-left: 0;
      }
    }

    @if $type==sp {}
  }
}

//================================================
// スライダー
//================================================
// 「恒電社の実績」と「自家消費型太陽光発電の導入は～」の共通クラス
.slideList {
  @if $type==pc {
    display: flex;
    margin-top: 40px;
  }

  .slideListItem {
    @if $type==pc {
      width: calc(33.3% - 1.0%);

      &:not(:last-child) {
        margin-right: 1.0%;
      }
    }

    .img {
      @if $type=="" {
        overflow: hidden;

        img {
          width: 100%;
          transition: .3s transform $ttf;
        }
      }

      img {
        @if $type==sp {}

        @if $type==pc {
          //トリミング
          // height: 15.9vw;
          // object-fit: cover;
          // max-height: 229px;
        }
      }
    }

    &>a {
      @if $type=="" {
        display: block;
        transition: .3s transform $ttf;
      }

      @if $type==pc {
        &:hover {
          img {
            transform: scale(1.1);
          }

          .moreBtn>span {
            background-image: url(../images/common/icon_arrow_more_hov.svg);
          }
        }
      }
    }

    .slideListTitle {
      @if $type=="" {
        color: $c_purple;
        font-weight: bold;
        margin-top: 16px;
      }
    }
  }
}

//slick 調整
.slick-list {
  @if $type=="" {}

  @if $type==sp {
    padding: 30px 8% !important;
  }

  @if $type==pc {
    padding: 30px 0 !important;
  }

  .img {
    @if $type=="" {
      overflow: hidden;

      img {
        width: 100%;
        transition: .3s transform $ttf;
      }
    }
  }

  .slick-slide {
    @if $type==sp {
      margin: 0 10px;
    }

    @if $type==pc {
      @if $type==pc {
        transform: scale(0.95);
        transition: .3s transform $ttf;
        transform-origin: bottom;

        &:not(:last-child) {
          margin-right: 0;
        }
      }

      &:not(.slick-center) {
        &:hover {
          img {
            transform: scale(1.1);
          }
        }
      }
    }
  }
}

.slick-dots {
  @if $type=="" {
    display: block;
    text-align: center;
    padding: 0;
    margin: -2px 0 0;
    width: 100%;
  }

  @if $type==sp {
    padding: 0 100px;
  }

  @if $type==pc {
    padding-right: 120px;
    text-align: right;
  }

  li {
    @if $type=="" {
      position: relative;
      display: inline-block;
      margin: 0 8px;
      cursor: pointer;
    }

    @if $type==sp {
      height: 10px;
      width: 10px;
    }

    @if $type==pc {
      height: 10px;
      width: 10px;
    }

    button {
      @if $type=="" {
        background: transparent;
        border-radius: 50%;
        border: 1px solid $c_purple;
        display: block;
        outline: none;
        line-height: 0px;
        font-size: 0px;
        padding: 4px;
        cursor: pointer;
      }

      @if $type==sp {
        height: 7px;
        width: 7px;
      }

      @if $type==pc {
        height: 7px;
        width: 7px;
      }


      &:hover,
      &:focus {
        @if $type=="" {
          outline: none;
        }
      }

      &:before {
        @if $type=="" {
          position: absolute;
          top: 0;
          left: 0;
          line-height: 20px;
          text-align: center;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
        }

        @if $type==sp {
          width: 20px;
          height: 20px;
        }

        @if $type==pc {
          width: 20px;
          height: 20px;
        }
      }
    }

    &.slick-active {
      button {
        @if $type=="" {
          background: $c_purple;
        }
      }

    }
  }
}

.slick-arrow {
  @if $type=="" {
    display: block;
    background-color: #fff;
    border: none;
    font-size: 0;
    border: 1px solid $c_purple;
    z-index: 3;
    cursor: pointer;
    transition: .3s all $ttf;

    &::before {
      transition: .3s border $ttf;
    }
  }

  @if $type==sp {
    box-shadow: 1px 1px 0 0 $c_purple;
    width: 40px;
    height: 38px;
  }

  @if $type==pc {
    box-shadow: 2px 2px 0 0 $c_purple;
    width: 40px;
    height: 30px;

    &:hover {
      background-color: $c_purple;

      &::before {
        border-color: #fff;
      }
    }
  }

  &.prev {
    @if $type=="" {
      border-right: none;
      border-radius: 3px 0 0 3px;
      cursor: pointer;
      position: absolute;
      bottom: -10px;
      right: 60px;
    }

    &::before {
      @if $type=="" {
        content: "";
        display: block;
        border-right: 1px solid $c_purple;
        border-bottom: 1px solid $c_purple;
        position: absolute;
        transform: translate(-50%, -50%)rotate(-225deg);
      }

      @if $type==sp {
        top: calc(50% + 1px);
        left: calc(50% + 2px);
        width: 8px;
        height: 8px;
      }

      @if $type==pc {
        top: calc(50% + 1px);
        left: calc(50% + 2px);
        width: 8px;
        height: 8px;
      }
    }
  }

  &.next {
    @if $type=="" {
      border-radius: 0 3px 3px 0;
      cursor: pointer;
      position: absolute;
      bottom: -10px;
      right: 20px;
    }

    &::before {
      @if $type=="" {
        content: "";
        display: block;
        border-right: 1px solid $c_purple;
        border-bottom: 1px solid $c_purple;
        position: absolute;
        transform: translate(-50%, -50%)rotate(-45deg);
      }

      @if $type==sp {
        top: calc(50% - -1px);
        left: calc(50% - 2px);
        width: 8px;
        height: 8px;
      }

      @if $type==pc {
        top: calc(50% - -1px);
        left: calc(50% - 2px);
        width: 8px;
        height: 8px;
      }
    }
  }
}

//================================================
// お取引先企業
//================================================
#logoBnr {

  .logoList {
    @if $type=="" {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
    }

    @if $type==sp {
      margin-top: 22px;
      gap: 10px 3.28%;
    }

    @if $type==tb {
      margin-top: 30px;
      gap: 15px 2.15%;
    }

    @if $type==pc {
      margin-top: 45px;
      gap: 25px 2.15%;
    }

    >li {
      @if $type=="" {
        overflow: hidden;
        border: 1px solid #ececec;
      }

      @if $type==sp {
        width: calc((100% * 1/3) - 2.2%);
      }

      @if $type==tb {
        width: calc((100% * 1/4) - 1.7%);
      }

      @if $type==pc {
        width: calc((100% * 1/6) - 1.8%);
      }
    }
  }
}


@import "_slick";